var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.multiple
        ? _c(
            "v-dialog",
            {
              attrs: {
                fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                persistent: "",
                scrollable: "",
                transition: "dialog-bottom-transition",
                width: "500px"
              },
              model: {
                value: _vm.dialog,
                callback: function($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        color: "primary",
                        dark: "",
                        height: "56",
                        "max-height": "56"
                      }
                    },
                    [
                      _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                      _c("v-spacer"),
                      _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-close",
                          "button-tooltip": "Close Dialog",
                          dark: "",
                          icon: ""
                        },
                        on: {
                          confirmed: function($event) {
                            _vm.dialog = false
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "ma-0 pa-0",
                      style: {
                        "max-height": _vm.$vuetify.breakpoint.mdAndUp
                          ? "400px"
                          : ""
                      }
                    },
                    [
                      _c(
                        "v-list",
                        { attrs: { flat: "" } },
                        [
                          _vm.selectedSome || _vm.selectedAll
                            ? _c(
                                "v-list-item",
                                { attrs: { color: "primary", "two-line": "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-subheader",
                                            [
                                              _vm._t(
                                                "selectedHeader",
                                                [
                                                  _vm._v(
                                                    " Selected (" +
                                                      _vm._s(
                                                        _vm.selectedArray.length
                                                      ) +
                                                      " of " +
                                                      _vm._s(
                                                        _vm.itemsArray.length
                                                      ) +
                                                      ") "
                                                  )
                                                ],
                                                {
                                                  itemsLength:
                                                    _vm.itemsArray.length,
                                                  selectedLength:
                                                    _vm.selectedArray.length
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedSome || _vm.selectedAll
                            ? _c("v-divider")
                            : _vm._e(),
                          _vm._l(_vm.selectedArray, function(item) {
                            return _c(
                              "div",
                              { key: item },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-icon",
                                      [_c("v-icon", [_vm._v("mdi-check")])],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v(_vm._s(_vm.getItemText(item)))
                                        ]),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(_vm._s(item))
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c("v-divider")
                              ],
                              1
                            )
                          }),
                          _vm.selectedSome || !_vm.selectedAll
                            ? _c(
                                "v-list-item",
                                { attrs: { color: "primary", "two-line": "" } },
                                [
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-subheader",
                                            [
                                              _vm._t("itemsHeader", [
                                                _vm._v(
                                                  " Available (Not Selected) "
                                                )
                                              ])
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.selectedSome || !_vm.selectedAll
                            ? _c("v-divider")
                            : _vm._e(),
                          _vm._l(
                            _vm.itemsArray.filter(function(itm) {
                              return (
                                _vm.selectedArray.indexOf(
                                  itm[_vm.itemValue]
                                ) === -1
                              )
                            }),
                            function(item) {
                              return [
                                _c(
                                  "div",
                                  { key: item[_vm.itemValue] },
                                  [
                                    _c(
                                      "v-list-item",
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          [_c("v-icon", [_vm._v("mdi-close")])],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(item[_vm.itemText]))
                                            ]),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                _vm._s(item[_vm.itemValue])
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c("v-divider")
                                  ],
                                  1
                                )
                              ]
                            }
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.dropDown
        ? [
            _c(
              "asyent-card",
              {
                attrs: { expandable: "", title: _vm.label },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "toolbar-actions",
                      fn: function() {
                        return [
                          _c("asyent-button", {
                            attrs: {
                              icon: _vm.$vuetify.breakpoint.smAndDown,
                              text: !_vm.$vuetify.breakpoint.smAndDown,
                              "button-icon": "mdi-view-list",
                              "button-tooltip": "View as list"
                            },
                            on: {
                              confirmed: function($event) {
                                _vm.dialog = true
                              }
                            }
                          }),
                          _vm.enableSelectAll && _vm.multiple && !_vm.isDisabled
                            ? _c("asyent-button", {
                                attrs: {
                                  "button-icon": _vm.buttonIcon,
                                  icon: _vm.$vuetify.breakpoint.smAndDown,
                                  text: !_vm.$vuetify.breakpoint.smAndDown,
                                  "button-tooltip": "Select All"
                                },
                                on: { confirmed: _vm.toggle }
                              })
                            : _vm._e(),
                          _vm.itemsReload &&
                          !_vm.dropDown &&
                          _vm.multiple &&
                          !_vm.noReload
                            ? _c("asyent-button", {
                                attrs: {
                                  icon: _vm.$vuetify.breakpoint.smAndDown,
                                  text: !_vm.$vuetify.breakpoint.smAndDown,
                                  "button-icon": "mdi-reload",
                                  "button-tooltip": "Reload List"
                                },
                                on: { confirmed: _vm.reloadItemsFromParent }
                              })
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3445651794
                )
              },
              [
                _vm.helpText
                  ? _c("p", { domProps: { textContent: _vm._s(_vm.helpText) } })
                  : _vm._e(),
                _c("asyent-form-field-error", {
                  attrs: { value: _vm.formErrors[_vm.name] }
                }),
                _vm.selectedSome || _vm.selectedAll
                  ? _c(
                      "v-subheader",
                      [
                        _vm._t(
                          "selectedHeader",
                          [
                            _vm._v(
                              " Selected (" +
                                _vm._s(_vm.selectedArray.length) +
                                " of " +
                                _vm._s(_vm.itemsArray.length) +
                                ") "
                            )
                          ],
                          {
                            availableLength: _vm.itemsArray.length,
                            selectedLength: _vm.selectedArray.length
                          }
                        )
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "v-chip-group",
                  {
                    attrs: {
                      mandatory: _vm.mandatory,
                      multiple: _vm.multiple,
                      column: ""
                    },
                    model: {
                      value: _vm.selectedArray,
                      callback: function($$v) {
                        _vm.selectedArray = $$v
                      },
                      expression: "selectedArray"
                    }
                  },
                  _vm._l(_vm.selectedArray, function(item) {
                    return _c(
                      "v-chip",
                      {
                        key: item,
                        attrs: {
                          disabled: _vm.isDisabled,
                          value: item,
                          filter: "",
                          large: "",
                          outlined: ""
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-no-wrap text-truncate chip-size text-caption bold"
                          },
                          [
                            _vm._v(_vm._s(_vm.getItemText(item))),
                            _c("br"),
                            _c("span", { staticClass: "font-italic" }, [
                              _vm._v(_vm._s(item))
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  1
                ),
                _vm.selectedSome || !_vm.selectedAll
                  ? _c(
                      "v-subheader",
                      [
                        _vm._t("availableHeader", [
                          _vm._v(" Available (Not Selected) ")
                        ])
                      ],
                      2
                    )
                  : _vm._e(),
                _c(
                  "v-chip-group",
                  {
                    attrs: { multiple: _vm.multiple, column: "" },
                    model: {
                      value: _vm.selectedArray,
                      callback: function($$v) {
                        _vm.selectedArray = $$v
                      },
                      expression: "selectedArray"
                    }
                  },
                  _vm._l(_vm.itemsArray, function(item) {
                    return _c(
                      "v-chip",
                      {
                        key: item[_vm.itemValue],
                        attrs: {
                          active:
                            _vm.selectedArray.indexOf(item[_vm.itemValue]) ===
                            -1,
                          disabled: _vm.isDisabled,
                          value: item[_vm.itemValue],
                          filter: "",
                          large: "",
                          outlined: ""
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "text-no-wrap text-truncate chip-size text-caption"
                          },
                          [
                            _vm._v(_vm._s(item[_vm.itemText])),
                            _c("br"),
                            _c("span", { staticClass: "font-italic" }, [
                              _vm._v(_vm._s(item[_vm.itemValue]))
                            ])
                          ]
                        )
                      ]
                    )
                  }),
                  1
                ),
                _c("br")
              ],
              1
            )
          ]
        : [
            _vm.viewMode
              ? _c("asyent-form-display", {
                  attrs: {
                    value: _vm.value,
                    "no-label": _vm.noLabel,
                    label: _vm.label
                  }
                })
              : _vm._e(),
            !_vm.noLabel && !_vm.viewMode && _vm.dropDown
              ? _c("div", [
                  _c("span", { staticStyle: { "font-size": "0.85rem" } }, [
                    _vm._v(_vm._s(_vm.label))
                  ])
                ])
              : _vm._e(),
            _vm.helpText && !_vm.viewMode
              ? _c("p", { domProps: { textContent: _vm._s(_vm.helpText) } })
              : _vm._e(),
            !_vm.viewMode
              ? _c("ValidationProvider", {
                  attrs: { name: _vm.label, rules: _vm.formFieldRules },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c(
                              "v-autocomplete",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "mt-2",
                                    attrs: {
                                      "aria-label": _vm.label,
                                      disabled: _vm.isDisabled,
                                      "error-messages": _vm.formFieldErrors(
                                        errors
                                      ),
                                      "item-text": _vm.itemText,
                                      "item-value": _vm.itemValue,
                                      items: _vm.items,
                                      loading: _vm.dataLoading,
                                      "small-chips": _vm.multiple,
                                      "auto-select-first": "",
                                      "cache-items": "",
                                      clearable: "",
                                      "deletable-chips": "",
                                      placeholder: "Please select?",
                                      label: "",
                                      dense: _vm.appConfig.forms.dense,
                                      flat: _vm.appConfig.forms.flat,
                                      solo: _vm.appConfig.forms.solo,
                                      "solo-inverted":
                                        _vm.appConfig.forms.soloInverted,
                                      filled: _vm.appConfig.forms.filled,
                                      outlined: _vm.appConfig.forms.outlined
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        !_vm.noReload &&
                                        _vm.itemsReload &&
                                        _vm.dropDown &&
                                        !_vm.itemsStatic &&
                                        !_vm.isDisabled
                                          ? {
                                              key: "prepend-inner",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click:
                                                          _vm.reloadItemsFromParent
                                                      }
                                                    },
                                                    [_vm._v("mdi-reload")]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          : null,
                                        {
                                          key: "append-outer",
                                          fn: function() {
                                            return [_vm._t("append-outer")]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "item",
                                          fn: function(data) {
                                            return [
                                              _vm.replaceItemData
                                                ? _vm._t("item", null, {
                                                    item: data.item
                                                  })
                                                : _vm._e(),
                                              !_vm.replaceItemData &&
                                              _vm.showItemAvatar
                                                ? _c("v-list-item-avatar", {
                                                    staticStyle: {
                                                      "justify-content":
                                                        "center !important"
                                                    },
                                                    attrs: {
                                                      color: "secondary"
                                                    },
                                                    domProps: {
                                                      textContent: _vm._s(
                                                        data.item[_vm.itemValue]
                                                      )
                                                    }
                                                  })
                                                : _vm._e(),
                                              !_vm.replaceItemData
                                                ? _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            data.item[
                                                              _vm.itemText
                                                            ]
                                                          )
                                                        }
                                                      }),
                                                      _vm.showItemCode
                                                        ? _c(
                                                            "v-list-item-subtitle",
                                                            {
                                                              domProps: {
                                                                innerHTML: _vm._s(
                                                                  data.item[
                                                                    _vm
                                                                      .itemValue
                                                                  ]
                                                                )
                                                              }
                                                            }
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.selectedArray,
                                      callback: function($$v) {
                                        _vm.selectedArray = $$v
                                      },
                                      expression: "selectedArray"
                                    }
                                  },
                                  "v-autocomplete",
                                  _vm.$attrs,
                                  false
                                ),
                                _vm.$listeners
                              )
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1728114189
                  )
                })
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }